import React from 'react'
import styled from 'styled-components'
import { Container } from 'components/RemarkRenderer'
import { color, mediaQuery } from 'utils/style'
import Image from '../Image'
import StoryblokComponent from '../StoryblokComponent'

const Wrapper = styled.div`
  && {
    padding: 0;
  }
`

const GridWrapper = styled.div`
  position: relative;
  background-color: white;
  z-index: 501;
  ${mediaQuery()}
`
const GridItem = styled.div`
  position: absolute;
  background-color: ${color('bg', 'color')};
  color: ${color('bg', 'contrast')};
  ${Container} {
    a {
      color: ${color('bg', 'highlight')};
    }
  }
  h1,
  h2,
  h3 {
    color: ${color('bg', 'highlight')};
  }
  h4,
  h5,
  h6 {
    color: ${color('bg', 'contrast')};
  }
  .title,
  .roofline,
  .subline {
    color: ${color('bg', 'contrast')};
  }
  button {
    background-color: ${color('bg', 'button')};
  }
  overflow: hidden;
  ${mediaQuery()}
`

export default function StoryblokTiles ({ data }) {
  const margin = 5
  const getPaddingByDevice = (device) => {
    const layout = data.layout.layouts[device]
    const unit = 100 / layout.cols
    return `padding-bottom: ${unit
      * layout.items.reduce((acc, item) => Math.max(acc, item.y + item.h), 0)}%;`
  }
  const tileMediaQuery = idx => (device) => {
    const layout = data.layout.layouts[device]
    const unit = 100 / layout.cols
    const item = layout.items[idx]
    return item
      ? `left: calc(${item.x * unit}% + ${margin}px);
            margin-top: calc(${item.y * unit}% + ${margin}px);
            width: calc(${item.w * unit}% - ${2 * margin}px);
            padding-bottom: calc(${unit * item.h}% - ${2 * margin}px);`
      : 'display:none;'
  }
  const dimensionsByDevice = idx => (device) => {
    const layout = data.layout.layouts[device]
    const item = layout.items[idx]
    return { w: item?.w, h: item?.h, ratio: item ? item.w / item.h : 1 }
  }
  return (
    <Wrapper>
      <GridWrapper media={getPaddingByDevice}>
        {data.tiles?.map((tile, idx) => (
          <GridItem
            key={tile.id}
            bg={tile.backgroundColor}
            media={tileMediaQuery(idx)}
          >
            <Image
              background
              src={tile.backgroundImage}
              alt={tile.imageAlt}
              title={tile.imageTitle}
              description={tile.imageDescription}
            />
            {tile.content?.map(content => (
              <StoryblokComponent
                key={content.id}
                component={content}
                dimensions={dimensionsByDevice(idx)}
              />
            ))}
          </GridItem>
        ))}
      </GridWrapper>
    </Wrapper>
  )
}
